import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/components";
import "element-ui/lib/theme-chalk/index.css";
import "./styles/index.less";
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
