export default [
  {
    path: "/agent",
    name: "agent",
    component: () => import("@/views/agent/index.vue"),
    meta: {
      title: "渠道合作"
    }
  },
  {
    path: "/area",
    name: "area",
    component: () => import("@/views/agent/Area.vue"),
    meta: {
      title: "区域招商"
    }
  }
];
