import Vue from "vue";
import {
  Button,
  Carousel,
  CarouselItem,
  Dialog,
  Input,
  Image,
  Pagination,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Message,
  Slider,
  Radio,
  RadioGroup,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  MessageBox
} from "element-ui";

Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
Vue.component(Button.name, Button);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Dialog.name, Dialog);
Vue.component(Input.name, Input);
Vue.component(Image.name, Image);
Vue.component(Message.name, Message);
Vue.component(Slider.name, Slider);
Vue.component(Radio.name, Radio);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(Pagination.name, Pagination);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Dialog.name, Dialog);
Vue.component(Progress.name, Progress);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(MessageBox.name, MessageBox);
