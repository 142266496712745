export default [
  {
    path: "/help",
    name: "help",
    component: () => import("@/views/help/index.vue"),
    meta: {
      title: "帮助"
    }
  }
];
