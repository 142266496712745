export default [
  {
    path: "/open",
    name: "open",
    component: () => import("@/views/open/index.vue"),
    meta: {
      title: "开通学籍"
    }
  }
];
