import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

let routes: any[] = [];
function importAll(r: any) {
  r.keys().forEach((key: string) => {
    routes = routes.concat(r(key).default);
  });
}

importAll(require.context("./routes/", true, /\.ts$/));

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    //to top

    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/views/Layout.vue"),
      children: routes
    },
    {
      path: "*",
      redirect: { path: "/" }
    }
  ]
});
router.beforeEach((to, from, next) => {
  //to and from are Route Object,next() must be called to resolve the hook}
  document.title = "光华商学官网-" + (to.meta && to.meta.title);
  next();
});
export default router;
