export default [
  {
    path: "/agency",
    name: "agency",
    component: () => import("@/views/agency/index.vue"),
    meta: {
      title: "关于我们"
    }
  }
];
